.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
#root .form-button {
  max-width: 100px;
  margin-top: 3em;
}

#root {
  margin: 1em 3em;
}
#root legend {
  margin-top: 2em;
}
.formDialog .MuiPaper-root {
  padding: 2em;
}

nav {
  .navigation {
    background: lightgrey;
    display: flex;
    padding: 0;
    li {
      flex: 1;
      list-style: none;
      padding: 1rem 0;
      &:hover,
      &:focus,
      &:visited {
        background: lightblue;
        color: darkblue !important;
      }
      &:active {
        color: darkblue !important;
      }
      text-align: center;
      a {
        text-decoration: none;
        display: block;
      }
    }
  }
}
